import React, { useEffect } from 'react';

const CancellationAndRefundPage: React.FC = () => {
	  useEffect(() => {
			window.scrollTo(0, 0);
		}, []);
	return (
		<div className="global-content-pages">
			<div className="container">
				<h1>Streamefy Refund Policy</h1>
				<p>
					<strong>Effective Date:</strong> 19 November 2024
				</p>

				<h2>1. General</h2>
				<p>
					Users may cancel their Streamefy subscription at any time. To initiate a cancellation, please contact our support team through the live
					support channel in your account or by emailing <a href="mailto:support@streamefy.com">support@streamefy.com</a>.
				</p>

				<h2>2. Cancellation Process</h2>
				<ul>
					<li> 
						<strong>Access Duration:</strong> Upon cancellation, users will retain full access to Streamefy services until the end of their current
						billing cycle, unless a refund is issued.
					</li>
					<li>
						<strong>Refund Issuance:</strong> If a refund is granted, the account will be placed in a canceled status immediately after the refund is
						processed.
					</li>
					<li>
						<strong>Outstanding Charges:</strong> During cancellation, we will review the account for any unpaid charges (e.g., unpaid standard quota
						usage, unpaid additional quota usage) and process payments as necessary.
					</li>
				</ul>

				<h2>3. Canceled Status</h2>
				<p>Once an account is fully canceled:</p>
				<ul>
					<li>
						<strong>Feature Access:</strong> Users will have limited access to features and functionalities.
					</li>
					<li>
						<strong>Content Accessibility:</strong> Content not transferred to another active user's account will become inaccessible.
					</li>
				</ul>

				<h2>4. Refunds</h2>
				<ul>
					<li>
						<strong>Eligibility:</strong> Refunds apply only to paid subscriptions. Users must explicitly request a refund within the specified refund
						window. Accounts that have utilized any quota during the current subscription period are ineligible for a refund.
					</li>
					<li>
						<strong>Partial Refunds:</strong> Streamefy does not process partial refunds. After the refund window closes, all purchases are final, and
						fees paid are non-refundable, except as required by law or as otherwise specified in our Terms of Service.
					</li>
					<li>
						<strong>Additional Charges:</strong> Charges for additional quota and account upgrades are not eligible for refunds.
					</li>
					<li>
						<strong>Account Abuse:</strong> If account abuse is identified, refund requests may be denied at Streamefy's discretion.
					</li>
					<li>
						<strong>Sales Commissions:</strong> All sales commissions paid to Streamefy are non-refundable.
					</li>
					<li>
						<strong>Termination by Streamefy:</strong> If Streamefy terminates an account for reasons outlined in our Terms of Service, no refunds
						will be issued, regardless of the timing.
					</li>
				</ul>

				<h2>5. Refund Window</h2>
				<ul>
					<li>
						<strong>Annual Prepaid Subscriptions:</strong> Refund requests must be made within fifteen (15) days after the initial paid subscription
						starts or ten (10) days after a scheduled renewal.
					</li>
					<li>
						<strong>Annual Paid Monthly Subscriptions:</strong> Refund requests must be made within five (5) days after the initial paid subscription
						starts or after a scheduled renewal.
					</li>
				</ul>

				<h2>6. Unpaid Standard Quota Usage (Applicable to Annual Paid Monthly Plans Only)</h2>
				<p>
					To prevent account abuse, an early cancellation fee may apply if quota usage exceeds the allotted amount on the effective date of
					cancellation for the current subscription cycle.
				</p>
				<ul>
					<li>
						<strong>Allotment Calculation:</strong> (Annual Plan Quota divided by 12) multiplied by the number of successful payments.
					</li>
					<li>
						<strong>Early Cancellation Fee:</strong> (Quota usage minus allotment) multiplied by the plan’s per GB rate.
					</li>
				</ul>
				<p>For detailed pricing information, please refer to our pricing page.</p>

				<h2>7. Contact Information</h2>
				<p>
					For any questions or to initiate a cancellation or refund request, please contact our support team through the live support channel in your
					account or by emailing <a href="mailto:support@streamefy.com">support@streamefy.com</a>.
				</p>
				<p>
					<em>Note: This policy is subject to change. Users will be notified of any significant changes to the refund policy.</em>
				</p>
				
                <h2>8. Refund Policy</h2>
				<p>
					We take 4-7 business days for processing you refunds.
				</p>
				
			</div>
		</div>
	);
};

export default CancellationAndRefundPage;
