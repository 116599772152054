import React, { useEffect } from 'react';

const AccountDeletionInstructions: React.FC = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="global-content-pages">
			<div className="container">
				<h1>Streamefy Account Deletion Instructions</h1>
				<p>Follow the steps below to delete your Streamefy account permanently :-</p>

				<h2>1. Log in to Your Streamefy Account</h2>
				<p>
					First, make sure you are logged into your Streamefy account. If you have forgotten your credentials, you can reset your password through the
					"Forgot Password" option on the login page.
				</p>
				<p>
					Log in Using Social Media Accounts (if applicable), If you signed up using a social media account like Facebook or Google, you can log in
					using the same social media credentials.Simply click on the respective social media login option to access your account.
				</p>

				<h2>2. Access the Dashboard</h2>
				<p>
					Once logged in, you will be redirected to the dashboard. If you wish to delete your account, go to the profile icon in the top right corner.
				</p>

				<h2>3. Click on Your Profile</h2>
				<p> After clicking on your profile icon your profile detail page will be displayed where you will find a "Delete Account" button.</p>

				<h2>4. Confirm Deletion</h2>
				<p>
					Once you click the "Delete Account" button, you will be prompted to confirm your action. After confirming, your account will be permanently
					deleted.
				</p>

				<h2>5. Need Assistance?</h2>
				<p>If you encounter any issues during the account deletion process, you can contact our support team:</p>
				<ul>
					<li>
						<strong>Email:</strong> <a href="mailto:support@streamefy.com">support@streamefy.com</a>
					</li>
					<li>
						<strong>Registered Office:</strong> B-203, Euphoria Suncity, Sarjapur Outer Ring Road, Bellandur, Bangalore - 560103, Karnataka
					</li>
				</ul>
				<p>
					Thank you for using Streamefy. If there's anything we can do to improve your experience, please let us know before you proceed with account
					deletion.
				</p>
			</div>
		</div>
	);
};

export default AccountDeletionInstructions;
