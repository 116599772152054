import axios, { AxiosResponse, AxiosError } from 'axios';
import { Constants } from '../../constants/Constants';
import { IAPI_Error_Response } from '../../types/ApiErrorResponse';

// Create an Axios instance
const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_ENDPOINT!,
	timeout: 1000,
	headers: { 'Content-Type': 'application/json' },
});

axiosInstance.interceptors.request.use(
	async (config) => {
		// if token required then read the login token (ACCESS TOKEN) and pass that to headers
		const token = localStorage.getItem(Constants.TOKEN);
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

axiosInstance.interceptors.response.use(
	(response: AxiosResponse) => {
		return response;
	},
	(error) => {
		if (error.response?.status === 401) {
			console.log('error from token instance ', error.response);
			// if api return 401 then we will logout the user
			// DeviceEventEmitter.emit('AuthorizationExpired', 'ERR_USER_ROLE_CHANGED')
		}
		return Promise.reject(error);
	},
);

// Utility function to handle errors
export const handleError = (error: AxiosError): void => {
	const { response } = error;
	if (response) {
		const errorData = response.data as IAPI_Error_Response;
		if (errorData.message) {
			throw errorData.message;
		} else if (errorData.errorMessage) {
			if (errorData.code === 409) {
				alert(errorData.errorMessage);
			} else {
			}
			throw errorData.errorMessage;
		} else if (errorData.errors) {
			throw errorData.errors[0]?.description;
		}
	}
	throw error;
};

// Define the type for URL and params
type Params = { [key: string]: any };

// Define API functions with proper type annotations
export const get = (url: string, params?: Params): Promise<any> => {
	return axiosInstance
		.get(url, { params })
		.then((response) => response.data)
		.catch((error) => handleError(error));
};

export const getAuth = (url: string, params?: Params): Promise<any> => {
	return axios
		.get(url, { params })
		.then((response) => response.data)
		.catch((error) => handleError(error));
};

export const post = (url: string, payload?: any): Promise<any> => {
	return axiosInstance
		.post(url, payload)
		.then((response) => response.data)
		.catch((error) => handleError(error));
};

// Naming 'delet' is not a mistake
// 'delete' is a reserved keyword, so we use 'delet' instead
export const delet = (url: string, payload: any = {}): Promise<any> => {
	return axiosInstance
		.delete(url, { data: payload })
		.then((response) => response.data)
		.catch((error) => handleError(error));
};

export const put = (url: string, payload?: any): Promise<any> => {
	return axiosInstance
		.put(url, payload)
		.then((response) => response.data)
		.catch((error) => handleError(error));
};

export const patch = (url: string, payload?: any, isAnotherUrl?: boolean): Promise<any> => {
	return axiosInstance
		.patch(url, payload)
		.then((response) => response.data)
		.catch((error) => handleError(error));
};
