import React, { useEffect } from 'react';

const TermsAndConditionsPage: React.FC = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className="global-content-pages">
			<div className="container">
				<h1>Streamefy Terms and Conditions</h1>
				<p>
					<strong>Effective Date:</strong> November 19, 2024
				</p>

				<h2>1. Agreement</h2>
				<p>
					Thank you for choosing Streamefy. By accessing or using our services and websites ("Services"), you agree to comply with and be bound by
					these Terms and Conditions ("Terms") and our Privacy Policy. If you are using the Services on behalf of a business or organization, you
					affirm that you have the authority to bind that entity to these Terms. If you do not agree with any part of these Terms, please refrain from
					using our Services.
				</p>

				<h2>2. Services</h2>
				<p>
					These Terms apply to all users of the Services, including those who contribute content. "Content" encompasses text, software, scripts,
					graphics, photos, sounds, music, videos, audiovisual combinations, interactive features, and other materials accessible through or
					contributed to the Services. Streamefy offers various products, software, and services via our website, including embeddable media and
					social media integration features.
				</p>
				<p>
					Our Services may contain links to third-party websites not owned or controlled by Streamefy. We are not responsible for the content, privacy
					policies, or practices of any third-party sites. By using our Services, you release Streamefy from any liability arising from your use of
					third-party websites.
				</p>

				<h2>3. Streamefy Accounts</h2>
				<p>
					To access certain features of our Services, you must create an account. You are responsible for maintaining the confidentiality of your
					account credentials and for all activities that occur under your account. Notify us immediately of any unauthorized use of your account.
					Streamefy is not liable for any losses resulting from unauthorized use of your account.
				</p>

				<h2>4. Business Use of Our Services</h2>
				<p>
					If you are using our Services on behalf of a business, that business agrees to these Terms. It will indemnify and hold Streamefy and its
					affiliates, officers, agents, and employees harmless from any claim, suit, or action arising from or related to the use of the Services or
					violation of these Terms, including liabilities or expenses from claims, losses, damages, suits, judgments, litigation costs, and attorneys'
					fees.
				</p>

				<h2>5. Your Content on Streamefy</h2>
				<p>
					Our Services allow you to upload, submit, store, send, or receive content. You retain ownership of any intellectual property rights in your
					content. By uploading content to Streamefy, you grant us a worldwide, non-exclusive, royalty-free license to use, host, store, reproduce,
					modify, create derivative works, communicate, publish, publicly perform, publicly display, and distribute such content. This license is for
					the purpose of operating, promoting, and improving our Services.
				</p>

				<h2>6. Your Responsibilities</h2>
				<p>
					You are solely responsible for your content and the consequences of submitting and publishing it on our Services. You affirm that you own or
					have the necessary licenses, rights, consents, and permissions to upload the content and grant Streamefy the rights described in these
					Terms. You agree that your content will not infringe upon any third-party rights or contain material that is unlawful, defamatory, or
					otherwise objectionable.
				</p>

				<h2>7. Prohibited Conduct and Use</h2>
				<p>You agree not to use the Services for any of the following:</p>
				<ul>
					<li>
						Uploading content that is illegal, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, invasive of another's privacy,
						hateful, or objectionable.
					</li>
					<li>Impersonating any person or entity or falsely stating or misrepresenting your affiliation with a person or entity.</li>
					<li>Engaging in activities that harm minors in any way.</li>
					<li>Uploading content you do not have the right to make available under law or contractual relationships.</li>
					<li>Uploading content that infringes any intellectual property rights.</li>
					<li>Uploading unsolicited advertisements or promotional materials.</li>
					<li>Uploading material with harmful software or viruses.</li>
					<li>Interfering with or disrupting the Services or networks.</li>
				</ul>

				<h2>8. Copyright Infringement and Notice under Indian Law</h2>
				<p>
					Streamefy respects the intellectual property rights of others and expects its users to do the same. If you believe your copyrighted work has
					been infringed, please contact us at <a href="mailto:support@streamefy.com">support@streamefy.com</a>.
				</p>

				<h2>9. Termination (as per Indian and Karnataka Jurisdiction)</h2>
				<p>
					Streamefy reserves the right to terminate accounts under certain conditions, including violating Terms or laws. Disputes will be resolved
					under Bengaluru jurisdiction.
				</p>
			</div>
		</div>
	);
};

export default TermsAndConditionsPage;
