import { IKeyValuePairs } from '../types/keyValuePairs';

export const Constants: IKeyValuePairs = {
	TOKEN: 'authToken',
};

export const Login_URL = 'https://app.streamefy.com/?tabId=1';
export const SignUp_URL = 'https://app.streamefy.com/?tabId=0';
export const Insta_URL = 'https://www.instagram.com/streamefyapp/';
export const Twitter_URL = 'https://x.com/streamefyApp';
export const YouTube_URL = 'https://youtube.com/@streamefyapp?si=lyBmzHDYZ5e9NMeQ';
